<template>
  <div class="recharge">
    <van-nav-bar
      :title="$t('RECHARGE')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div class="container">
      <div class="b-b">
        <van-cell class="moneybox">
          <div class="title" slot="title">{{$t('RECHARGEMONEY')}}</div>
          <div slot="default">₱{{balance || 0}}</div>
        </van-cell>
        <van-cell class="moneybox">
          <div class="title" slot="title">{{$t('BANKCRAD')}}</div>
          <div slot="default">{{bank_card_no}}</div>
        </van-cell>
        <van-cell class="moneybox">
          <div class="title" slot="title">{{$t('BANKNAME')}}</div>
          <div slot="default">{{bank_name}}</div>
        </van-cell>
        <van-cell class="moneybox">
          <div class="title" slot="title">{{$t('CONTACTNAME')}}</div>
          <div slot="default">{{bank_reserved_name}}</div>
        </van-cell>
      </div>
    </div>
    <div class="tips">*{{$t('UPLOADVOUCHER')}}</div>
    <div class="IDUploadbox">
      <van-uploader v-if="showImg" class="Uploader" :after-read="afterReadPositive">
        <div class="UploadArea">
          <div class="cameraBox">
            <van-image round class="cameraImg" :src="imgurl" />
          </div>
          <div class="UploadInstructions">{{$t('UPLOADONEPROTO')}}</div>
        </div>
      </van-uploader>
      <div v-else class="showimg" @click="remove">
        <div class="del">X</div>
        <img class="img" :src="imgUrl" >
      </div>
    </div>
    <div class="subbox">
      <van-button @click="sub" class="sub" color="#00BFFF" type="primary" block>{{$t('SUBMIT')}}</van-button>
    </div>
  </div>
</template>

<script>
import { torecharge, uploadPhoto, getBankInfo } from '@/api/wallet'
import '../My/css/my.css'
import { Toast } from 'vant'
export default {
  name: 'recharge',
  data () {
    return {
      imgUrl: null,
      showImg: true,
      bank_name: '',
      bank_card_no: '',
      bank_reserved_name: '',
      imgurl: require('./img/money.jpg')
    }
  },
  created () {
    // this.$route.params.money
    // console.log(this.$route.params)
    this.loadgetBankInfo()
  },
  computed: {
    balance () {
      // let num = this.$route.params.money
      // console.log(this.getRandomIntInclusive(11, 99))
      if (this.$route.params.money) {
        return Number(this.$route.params.money) + Number('0.' + this.getRandomIntInclusive(11, 99))
      } else {
        return false
      }
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    async afterReadPositive (file) {
      const formData = new FormData()
      formData.append('picture', file.file)
      const { data } = await uploadPhoto(formData)
      console.log(data)
      if (data.code === 200) {
        this.imgUrl = 'http://' + data.data
        this.showImg = false
      }
    },
    async sub () {
      if (!this.imgUrl) {
        Toast(this.$t('IMGNOT'))
        return false
      }
      const { data } = await torecharge({
        money: this.$route.params.money,
        imageUrl: this.imgUrl
      })
      if (data.code === 200) {
        Toast(this.$t('SUBMITSUCCEED'))
      }
      if (data.code === 500) {
        Toast(data.message)
      }
      console.log(data)
    },
    remove () {
      this.showImg = true
      this.imgUrl = null
    },
    async loadgetBankInfo () {
      try {
        const { data } = await getBankInfo()
        if (data.code === 200) {
          console.log(data)
          this.bank_name = data.data.bank_name
          this.bank_card_no = data.data.bank_card_no
          this.bank_reserved_name = data.data.bank_reserved_name
        }
      } catch (error) {
        console.log(error)
      }
    },
    getRandomIntInclusive (min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
  }
}
</script>

<style scoped>
.img {
  width: 100%;
  height: 100%;
}
.del {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 50px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  line-height: 30px;
  text-indent: .5em;
}
.showimg {
  position: relative;
  width: 100%;
  height: 100%;
}
.subbox {
  padding: 20px;
}
.sub {
  font-size: 18px;
}
.tips {
  text-align: center;
  padding: 10px;
  font-size: 13px;
  color: #666;
}
.IDUploadbox {
  box-sizing: border-box;
  padding: 3px;
  height: 200px;
  background-color: #fff;
}
.UploadArea {
  width: 367px;
  height: 190px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
}
.cameraBox {
  margin: 0 auto;
  margin-top: 40px;
  width: 70px;
  height: 70px;
}
.cameraImg {
  width: 100%;
  height: 100%;
}
.UploadInstructions {
  margin: 0 auto;
  width: 150px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #fff;
  font-size: 14px;
  background: rgb(39, 144, 248);
  opacity: .5;
  padding: 0 9px;
  margin-top: 20px;
  border-radius: 8px;
}
</style>
